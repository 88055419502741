
import { defineComponent, ref, toRef } from "vue";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import ApiService from "@/core/services/ApiService";
import store from "@/store";
import { Actions } from "@/store/enums/StoreEnums";
import { Workflow } from "@/store/modules/AuthModule";
import { useStore } from "vuex";

export default defineComponent({
  name: "edit-marketing-program-modal",
  components: {},
  props: ["account", "marketingProgram", "workflowTemplateId"],

  watch: {
    "marketingProgram.workflow_template_id": function () {
      this.initialize();
    },
  },

  setup(props, context) {
    const formRef = ref<null | HTMLFormElement>(null);
    const editMarketingProgramRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);
    const store = useStore();
    let w = new Workflow();
    let metaData = toRef(props, "marketingProgram");
    let workflowData = ref(w);
    const rules = ref({});
    let baseUrl = process.env.VUE_APP_API_URL;
    const previewEmail = ref<string>("");

    const initialize = async () => {
      if (metaData.value !== undefined) {
        if (metaData.value.workflow_id === null) {
          loading.value = true;
          try {
            let result = await ApiService.post("api/v1/workflows", {
              workflowTemplate:
                "/api/v1/workflow_templates/" +
                metaData.value.workflow_template_id,
            });
            workflowData.value = result.data;
          } catch (err) {
            console.log(err);
          } finally {
            loading.value = false;
          }
        } else {
          loading.value = true;
          try {
            let result = await ApiService.get(
              "api/v1/workflows",
              metaData.value.workflow_id
            );
            workflowData.value = result.data;
            context.emit("saved");
          } catch (err) {
            console.log(err);
          } finally {
            loading.value = false;
          }
        }
      }
    };

    const submit = () => {
      if (!formRef.value) {
        return;
      }

      formRef.value.validate((valid) => {
        if (valid) {
          if (metaData.value.id !== "") {
            let properties = {};
            metaData.value.workflowPropertyValues.forEach(function (item) {
              properties[item.name] = item.value;
            });
            let validPost = {
              enabled: metaData.value.enabled,
              optInExistingCustomers: metaData.value.optInExistingCustomers,
              theme: metaData.value.theme,
              customProperties: properties,
            };
            loading.value = true;
            ApiService.update("api/v1/workflows", metaData.value.id, {
              ...validPost,
            })
              .then(() => {
                hideModal(editMarketingProgramRef.value);
                Swal.fire({
                  text: "Marketing program options saved!",
                  icon: "success",
                  buttonsStyling: false,
                  confirmButtonText: "Ok",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                });
                context.emit("saved");
              })
              .catch((err) => {
                Swal.fire({
                  text: "Error saving marketing program! " + err,
                  icon: "error",
                  buttonsStyling: false,
                  confirmButtonText: "Ok",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                });
              })
              .finally(() => {
                loading.value = false;
              });
          }
        } else {
          Swal.fire({
            text: "Sorry, looks like there are some errors detected, please try again.",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Ok, got it!",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
          return false;
        }
      });
    };

    return {
      workflowData,
      metaData,
      submit,
      loading,
      initialize,
      formRef,
      rules,
      editMarketingProgramRef,
      baseUrl,
      previewEmail,
    };
  },
});
