
import { defineComponent, onMounted, ref, watch } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import EditMarketingProgramModal from "@/views/sbmx/modals/EditMarketingProgramModal.vue";

export default defineComponent({
  name: "marketingPrograms",
  components: { EditMarketingProgramModal },
  setup() {
    const store = useStore();
    const selectedMarketingProgram = ref({});
    let baseUrl = process.env.VUE_APP_API_URL;

    const loadEnabledMarketingPrograms = function () {
      store.dispatch(Actions.REFRESH_ENABLED_MARKETING_PROGRAMS);
    };

    const setActiveMarketingProgram = (program) => {
      selectedMarketingProgram.value = program;
    };

    onMounted(() => {
      setCurrentPageBreadcrumbs("Marketing Programs", []);
      loadEnabledMarketingPrograms();
    });

    return {
      loadEnabledMarketingPrograms,
      setActiveMarketingProgram,
      selectedMarketingProgram,
      baseUrl,
    };
  },
});
